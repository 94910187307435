/* Loading Container */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f9f9f9;
    text-align: center;
  }
  
  /* Spinner Styles */
  .apple-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .apple-spinner .circle {
    width: 15px;
    height: 15px;
    margin: 3px;
    border-radius: 50%;
    background-color: #000;
    animation: apple-bounce 1.2s infinite ease-in-out both;
  }
  
  .apple-spinner .circle:nth-child(1) {
    animation-delay: -0.36s;
  }
  
  .apple-spinner .circle:nth-child(2) {
    animation-delay: -0.24s;
  }
  
  .apple-spinner .circle:nth-child(3) {
    animation-delay: -0.12s;
  }
  
  @keyframes apple-bounce {
    0%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }
  
  /* Loading Text */
  .loading-text {
    font-size: 18px;
    color: #333;
    margin-top: 20px;
    font-family: 'San Francisco', Arial, sans-serif; /* Apple-like font */
  }
  