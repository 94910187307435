/* Custom Scrollbar Styling */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #faf9f6;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #d4af37;
  border-radius: 10px;
  border: 3px solid #faf9f6;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342;
}

/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

body {
  line-height: 1.6;
  background-color: #faf9f6;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}


/* Dashboard Styles */
.dashboard {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the dashboard takes full height of the viewport */
  padding: 40px 0;
}

.dashboard-main {
  flex: 1; /* Makes the main content take up remaining space */
}

.welcome-section {
  text-align: center;
  margin-bottom: 40px;
}

.welcome-section h2 {
  font-size: 2.5rem;
  color: #3a506b;
  margin-bottom: 10px;
}

.welcome-section p {
  font-size: 1.2rem;
  color: #555555;
}

/* Metrics Section */
.metrics-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.metric-card {
  flex: 1;
  min-width: 250px;
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.metric-card h3 {
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: #3a506b;
}


#metric-text{
  font-size: 1.2rem;
  font-weight: bold;
  color: #d4af37;
  margin: 5px 0;
}

/* Outstanding Claims Number */
#outstanding-claims-count {
  font-size: 4rem;
  font-weight: bold;
  color: #d4af37;
  margin: 10px 0;
}

.metric-card .btn-file-claim,
.metric-card .btn-check-claims {
  background: #d4af37;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.metric-card .btn-file-claim:hover,
.metric-card .btn-check-claims:hover {
  background: #b89c2a;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

/* File a Claim Button Styling with Black Border */
.metric-card .btn-file-claim {
  background: #d4af37; /* Keep the existing background */
  color: #ffffff; /* Keep the existing text color */
  padding: 12px 24px;
  border-radius: 20px;
  border: 2px solid #000000; /* Add a black border */
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.metric-card .btn-file-claim:hover {
  background: #b89c2a; /* Keep the hover background */
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3); /* Keep the hover shadow */
}


/* Claims Section */
.claims-section {
  margin-top: 50px;
}

.claims-section h2 {
  font-size: 2rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center;
}

/* Claims Section */
.claims-section {
  margin-top: 50px;
}

.claims-section h2 {
  font-size: 2rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center; /* Center the H2 */
}

/* Styling for Show More/Less and Selector */
.claims-header {
  display: flex;
  flex-direction: column; /* Stack elements */
  align-items: center; /* Center the h2 */
  margin-bottom: 20px;
  position: relative; /* Ensure child elements can move freely */
}

.show-more-container {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 10px; /* Move it to the left */
  bottom: -10px; /* Move it down */
}

.claims-selector {
  margin-right: 15px;
  padding: 6px 12px;
  border-radius: 10px;
  border: 2px solid #d4af37;
  background-color: #faf9f6;
  color: #333;
  font-size: 1rem;
}

.btn-show-more {
  background-color: #d4af37;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 15px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.btn-show-more:hover {
  background-color: #b89c2a;
  transform: scale(1.05);
}


.claims-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Update the claim-item structure */
.claim-item {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 200px; /* Ensure minimum height for proper spacing */
}

/* Adjust the claim-info to use grid instead of flex */
.claim-info {
  display: grid;
  grid-template-columns: 1fr auto; /* Two columns: details and file list */
  gap: 30px; /* Space between columns */
  width: 100%;
  min-height: 150px; /* Minimum height to ensure spacing */
}

/* Adjust claim details to maintain spacing */
.claim-details {
  position: relative;
  padding-bottom: 60px; /* Space for the notice icon */
}

.claim-item h4 {
  font-size: 1.5rem;
  color: #3a506b;
  margin-bottom: 10px;
}

/* Claim Status Colors */
.claim-status {
  font-weight: bold;
}

/* These classes will be applied via JavaScript */
.status-in-progress {
  color: #ffa500; /* Orange for In Progress */
}

/* Resolved Claim Status with Glow and Animation */
.status-resolved {
  color: #28a745; /* Green color for resolved status */
  font-weight: bold;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease; /* Smooth scaling transition */
  animation: pulse 2s ease-in-out infinite; /* Continuous animation */
}

/* Glow effect */
.status-resolved::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  opacity: 0.6;
  transition: box-shadow 0.3s ease;
}

/* Subtle pulsing animation */
@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05); /* Slightly enlarge at midpoint */
  }
}


.status-pending {
  color: #dc3545; /* Red for Pending */
}

/* Payout styles */
.claim-payout {
  font-size: 1rem; /* Slightly larger font */
  color: #28a745; /* Green color to match resolved status */
  font-weight: bold; /* Bold text */
  margin-top: 5px; /* Add some space above */
  cursor: pointer; /* Indicate it's clickable */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover */
}

.claim-payout:hover {
  color: #218838; /* Darker green on hover */
  transform: scale(1.05); /* Slightly increase size on hover */
  text-decoration: underline; /* Add underline on hover */
}

/* File List Styles */
/* Keep the file-list section next to the claim-details */
/* File list adjustments */
.file-list {
  min-width: 200px; /* Minimum width for file list */
  max-width: 300px; /* Maximum width for file list */
}

.file-list p {
  font-size: 1.2rem; /* Increase the font size */
  font-weight: bold; /* Make it bold */
  color: #3a506b; /* Use the primary color for consistency */
  border-bottom: 2px solid #d4af37; /* Add a bottom border to make it stand out */
  margin-bottom: 10px; /* Space it out from the list */
  text-align: right; /* Align to the right for emphasis */
}

.file-list ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 0.95rem; /* Slightly smaller font for the list items */
  color: #555555; /* Use a softer color for attractiveness */
}

.file-list ul li {
  margin-bottom: 8px;
  transition: color 0.3s ease; /* Add transition for hover effect */
}

.file-list a {
  color: #3a506b;
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: 500; /* Make the text slightly bold */
}

.file-list a:hover {
  color: #d4af37;
  font-weight: 600; /* Make it bolder on hover for emphasis */
}

.btn-upload-file {
  background: #d4af37;
  color: #ffffff;
  padding: 6px 12px; /* Reduce padding to make it smaller */
  border-radius: 15px; /* Slightly smaller, rounder button */
  text-decoration: none;
  font-size: 0.8rem; /* Reduce font size */
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin-top: 10px;
}

.btn-upload-file:hover {
  background: #b89c2a;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

.remove-file-icon {
  cursor: pointer;
  margin-left: 8px;
  color: #ff3b30; /* Apple-like red for delete */
  transition: color 0.2s ease-in-out;
}

.remove-file-icon:hover {
  color: #ff1e1e;
}


/* Notice Icon Styles */
.claim-notice-icon {
  position: absolute;
  bottom: -12px; /* Move icon higher within the claim item */
  left: -10px;   /* Adjust its left positioning */
  width: 60px;  /* Keep the size the same */
  height: 60px;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.claim-notice-icon-resolved {
  position: absolute;
  bottom: 10px; /* Move icon higher for resolved claims */
  left: 10px;
  width: 60px;
  height: 60px;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.claim-notice-icon:hover,
.claim-notice-icon-resolved:hover {
  transform: scale(1.1);
  opacity: 0.8;
}

/* Claim Remarks Section */
.claim-remarks-section {
  position: relative;
  top: 15px;
  margin-left: 75px; /* Sleek positioning */
  margin-top: -10px;  /* Slightly lowered for elegance */
  font-size: 0.95rem;
  color: #3a506b;  /* Clean, modern dark color */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  background: #fafafa;  /* Subtle background to mimic Apple's soft, clean interfaces */
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05); /* Light shadow for depth */
}

/* Show remarks only for the selected claim */
.claim-item.selected .claim-remarks-section {
  display: block; /* Display remarks for the selected card */
}

.remark-label {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #d4af37; /* Subtle golden line to add sophistication */
}

.remark-text {
  font-size: 0.95rem;
  color: #555555;
  line-height: 1.6;
  margin-top: 5px;
}

.no-remark-text {
  font-size: 0.95rem;
  color: #888;
  text-align: left; /* Align to match the section */
  margin-left: 0; /* Remove any extra margin */
  font-style: italic;
  margin-left: 30px;
}

.remark-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.remark-navigation button {
  background: #d4af37;
  color: #ffffff;
  border: none;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.remark-navigation button:disabled {
  background: #ddd; /* Greyed out when disabled */
  cursor: not-allowed;
}

.remark-navigation button:hover:not(:disabled) {
  background: #b89c2a;
  transform: scale(1.05);
}

/* Pop-up Claim Remarks Section */
.popup-claim-remarks-section {
  position: absolute;
  bottom: 10px; /* Adjust positioning as needed */
  right: 5px;
  z-index: 1100; /* Higher z-index to be above the modal */
  background-color: #ffffff; /* White background to stand out */
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  width: 285px; /* Adjust width to fit content */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

/* Popup Claim Remark Label */
.popup-remark-label {
  font-size: 1rem;
  font-weight: 600;
  color: #3a506b;
  margin-bottom: 5px;
  border-bottom: 1px solid #d4af37; /* Subtle golden line for emphasis */
}

/* Popup Remark Text */
.popup-remark-text {
  font-size: 0.95rem;
  color: #555555;
  line-height: 1.6;
  margin-top: 5px;
}

/* Popup No Remark Text */
.popup-no-remark-text {
  font-size: 0.95rem;
  color: #888;
  font-style: italic;
  margin-left: 30px;
}





/* Loading for Filed Claims Section */
.claims-loading-container,
.no-claims-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin: auto;
}

/* Spinner Styling */
.claims-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #d4af37;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Keyframes for spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Styling for no claims message */
.no-claims-message p {
  font-size: 1.1rem;
  color: #3a506b;
  margin-top: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.no-claims-message strong {
  font-size: 1.3rem;
  color: #d4af37; /* Match the spinner's top color */
}


/* Pop-up Styles */
.claim-popup {
  position: absolute;
  bottom: 2px; /* Adjusted to move with the icon, keeping it above the icon */
  left: 60px; /* Align with the new position of the icon */
  background: rgba(58, 80, 107, 0.9);
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-size: 0.9rem;
  max-width: 250px;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none; /* Prevents interference */
}

.claim-details:hover .claim-popup,
.claim-details:focus .claim-popup {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto; /* Allows interaction when visible */
}

/* Resolved Claim Pop-up */
.claim-popup-resolved {
  position: absolute;
  bottom: 80px; /* Adjusted to move with the resolved claim icon */
  left: 10px; /* Align with the new position of the resolved icon */
  background: rgba(58, 80, 107, 0.9);
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-size: 0.9rem;
  max-width: 250px;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.3s ease, transform 0.3s ease;
  pointer-events: none; /* Prevents interference */
}

.claim-details:hover .claim-popup-resolved,
.claim-details:focus .claim-popup-resolved {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto; /* Allows interaction when visible */
}

/* Claim Filing Pop-up Window Styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 900; /* Below the popup but above everything else */
  display: none; /* Initially hidden */
}

/* Show overlay and popup when active */
#file-claim-popup.active {
  display: block;
}

.overlay.active {
  display: block;
}

/* Add transition for smooth appearance */
#file-claim-popup,
.overlay {
  transition: opacity 0.3s ease;
}

/* Add transition for smooth appearance */
.overlay,
.claim-popup-window {
  transition: opacity 0.3s ease;
}

#file-claim-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
  z-index: 1000;
  max-width: 600px;
  width: 90%;
  display: none; /* Initially hidden */
  overflow-y: auto; /* Allow scrolling within the popup */
  max-height: 90%; /* Ensure the popup does not exceed the viewport height */
}

/* Custom Scrollbar Styling for Popup */
#file-claim-popup::-webkit-scrollbar {
  width: 12px;
}

#file-claim-popup::-webkit-scrollbar-track {
  background: #faf9f6;
  border-radius: 10px;
}

#file-claim-popup::-webkit-scrollbar-thumb {
  background-color: #d4af37;
  border-radius: 10px;
  border: 3px solid #faf9f6;
}

#file-claim-popup::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342;
}

.claim-popup-content {
  position: relative;
}

.claim-popup-window h2 {
  font-size: 2rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center; /* Center the text */
}

/* Claims Overview Pop-up Window Styles */
#claims-overview-popup.active {
  display: block !important;
  opacity: 1;
  transition: opacity 0.3s ease;
}

#claims-overview-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
  z-index: 1000;
  max-width: 90%;
  width: 80%;
  display: none; /* Initially hidden */
  overflow-y: auto; /* Allow scrolling within the popup */
  max-height: 80%; /* Ensure the popup does not exceed the viewport height */
}

/* Custom Scrollbar Styling for Claims Popup */
#claims-overview-popup::-webkit-scrollbar {
  width: 12px;
}

#claims-overview-popup::-webkit-scrollbar-track {
  background: #faf9f6;
  border-radius: 10px;
}

#claims-overview-popup::-webkit-scrollbar-thumb {
  background-color: #d4af37;
  border-radius: 10px;
  border: 3px solid #faf9f6;
}

#claims-overview-popup::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342;
}

/* Custom Styling for Claims Overview */
.claims-popup-content {
  background: #f9f9f9;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

/* Popup Title */
.claims-popup-window h2 {
  font-size: 2rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center;
}

/* Grid Layout for Claims */
.claims-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

/* Individual Claim Row */
.claims-row {
  position: relative; /* Ensure positioning works for absolute elements inside */
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.claims-row:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.claim-popup-notice-icon {
  position: absolute;
  top: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
  width: 52px; /* Adjust the size as necessary */
  height: 52px;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.claim-notice-icon:hover {
  transform: scale(1.5);
  opacity: 0.8;
}

.claim-popup-id {
  color: #333; /* Deep grey text for a refined look */
  font-size: 1.15rem; /* Subtle emphasis on the text size */
  font-weight: 600; /* Moderately bold for a sophisticated appearance */
  border-radius: 8px; /* Smooth and slightly more rounded corners */
  padding: 6px 10px; /* Padding for better readability */
  display: inline-block; /* Keeps the element compact around the text */
  margin-bottom: 8px; /* Provides some space below the element */
  text-align: center; /* Centers the text neatly */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transitions for interactions */
}

.claim-popup-id:hover {
  transform: translateY(-2px); /* Subtle lift effect on hover */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2); /* Slightly deeper shadow on hover for emphasis */
}


.claims-row h4{
  font-size: 1.5rem;
  color: #3a506b;
}

.claim-column {
  flex: 1;
  margin-bottom: 10px;
  color: #3a506b;
}

.claim-column p {
  margin: 5px 0;
}

.claim-column strong {
  color: #3a506b;
}

.claim-file-list {
  list-style-type: none; /* Remove bullet points */
  padding: 0;
  margin: 0;
}

.claim-file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px; /* Space between file items */
  padding: 8px 12px; /* Add more padding for a comfortable look */
  border: 1px solid rgba(200, 200, 200, 0.5); /* Soft, subtle border */
  border-radius: 10px; /* Rounded corners for a smoother feel */
  background-color: #ffffff; /* Light background for a clean look */
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth interactions */
}

.claim-file-item:hover {
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow on hover */
}

.claim-file-item a {
  text-decoration: none;
  color: #0073e6; /* Custom link color that complements the palette */
  font-weight: 500;
  flex-grow: 1; /* Take up available space */
  transition: color 0.2s ease; /* Smooth color transition */
}

.claim-file-item a:hover {
  text-decoration: none; /* Keep clean, no underline on hover */
  color: #C8A600; /* Gold color for the link */
}

.claim-file-item-icon {
  margin-right: 10px; /* Space between icon and text */
  color: #666; /* Subtle icon color */
  font-size: 1.2rem; /* Slightly larger icon for emphasis */
}


.claim-remove-file-icon {
  cursor: pointer;
  color: #ff4d4f; /* Red color for the remove icon */
  font-size: 14px;
  margin-left: 8px;
}

.claim-remove-file-icon:hover {
  color: #d9363e; /* Darker red on hover */
}

.claim-no-files {
  font-style: italic;
  color: #888;
  margin-top: 5px;
}


/* Enhanced Close Button Styles */
.close-popup {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #3a506b;
  color: #ffffff;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.close-popup:hover {
  background: #d4af37;
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* Upload Files Button */
.btn-upload-file {
  background: #d4af37;
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 15px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin-top: 10px;
  text-align: center;
}

.btn-upload-file:hover {
  background: #b89c2a;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

/* Enhanced Close Button Styles */
.close-popup {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #3a506b;
  color: #ffffff;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.close-popup:hover {
  background: #d4af37;
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.file-claim-form label {
  display: block;
  font-size: 1rem;
  color: #3a506b;
  margin-bottom: 8px;
  text-align: left;
}

.file-claim-form input,
.file-claim-form select,
.file-claim-form textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 2px solid #d4af37;
  background-color: #faf9f6;
  color: #333;
  font-size: 1rem;
}

.file-claim-form .claim-type-toggle {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.file-claim-form .claim-type-toggle .toggle-option {
  display: flex;
  flex-direction: column; /* Stack label on top of the radio button */
  align-items: center; /* Center align the label and radio button */
}

.file-claim-form .claim-type-toggle label {
  font-size: 1rem;
  color: #3a506b;
  margin-bottom: 5px; /* Space between label and radio button */
}

.file-claim-form .claim-type-toggle input[type="radio"] {
  margin: 0;
}

.file-claim-form .upload-instructions {
  color: #3a506b;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.uploaded-files-list {
  text-align: left;
  margin-bottom: 20px;
}

.upload-instructions {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 10px;
}

.upload-instructions-list {
  margin-left: 20px;
  font-size: 0.9rem;
  color: #555;
}


.btn-submit-claim {
  background: #d4af37;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 20px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  display: block; /* Make the button block-level */
  margin: 0 auto; /* Center the button */
}

.btn-submit-claim:hover {
  background: #b89c2a;
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

.payment-disclaimer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #555;
  text-align: center;
}

.payment-disclaimer p {
  margin: 0;
  line-height: 1.4;
}

.file-claim-form input[type="file"] {
  position: relative;
  z-index: 1010; /* Ensure it's clickable above other elements */
}

/* Styling for the additional details message box */
.details-textarea {
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #d4af37;
  background-color: #faf9f6;
  color: #333;
  font-size: 1rem;
  margin-bottom: 20px;
  resize: vertical; /* Allows the textarea to be resized vertically */
  transition: border-color 0.3s ease;
}

.details-textarea:focus {
  border-color: #f9d342; /* Highlight border on focus */
  outline: none;
}

.terms-conditions {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the elements for balance */
  gap: 12px; /* Add a gap between elements */
  padding: 10px;
  background: #f8f9fa; /* Light background for subtle contrast */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  max-width: 500px; /* Constrain width for a clean look */
  margin: 0 auto; /* Center the container */
  margin-bottom: 15px;
}

.terms-label {
  font-size: 1rem; /* Slightly larger font for better readability */
  color: #3a506b;
  white-space: nowrap; /* Prevent text wrapping */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* Apple-like font stack */
}

.terms-link {
  margin-top: 2px;
  color: #d4af37; /* Blue shade for a classic Apple-like link */
  text-decoration: none;
  font-weight: 500; /* Slightly bold for emphasis */
}

.terms-link:hover {
  color: #0056b3; /* Darker shade on hover */
  text-decoration: underline; /* Add underline on hover for interactivity */
}

.terms-checkbox {
  margin-top: 15px;
  appearance: none;
  width: 10px !important;
  height: 20px;
  border: 2px solid #d4af37;
  border-radius: 4px;
  background: #fff;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.terms-checkbox:checked {
  background-color: #d4af37;
  border-color: #d4af37;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M6 10.8L2.6 7.4l-1.4 1.4L6 13.6l9-9L13.6 3 6 10.8z'/%3E%3C/svg%3E");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #f8f9fa; /* Light, clean background */
  border-radius: 12px;
  padding: 30px;
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  z-index: 1100;
}

.confirmation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.confirmation-details {
  margin-bottom: 15px;
  text-align: left;
  width: 100%;
}

.confirmation-details p {
  font-size: 16px;
  margin: 6px 0;
  color: #333;
}

.confirmation-message {
  margin-bottom: 20px;
  font-size: 17px;
  line-height: 1.5;
  color: #555;
  text-align: center;
}

.confirmation-highlight {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  background: #eaf3ff; /* Light blue background for emphasis */
  padding: 10px;
  border-radius: 8px;
}

.confirmation-email {
  font-size: 16px;
  color: #007aff; /* Apple-like blue for a softer touch */
  margin-bottom: 10px;
}

.confirmation-instructions {
  font-size: 16px;
  color: #555;
  background: #f0f0f5; /* Subtle gray background for emphasis */
  padding: 8px;
  border-radius: 6px;
}


.confirmation-message {
  margin-bottom: 15px;
  font-size: 17px;
  line-height: 1.4;
  color: #555;
}

.btn-close-confirmation {
  background-color: #007aff; /* A more Apple-like blue */
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.btn-close-confirmation:hover {
  background-color: #005bb5;
}

.close-popup {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.close-popup:hover {
  color: #007aff;
}

.confirmation-disclaimer {
  font-size: 14px;
  color: #888;
  margin-top: 20px;
  line-height: 1.4;
}

.terms-link {
  color: #007aff;
  text-decoration: none;
  font-weight: 500;
}

.terms-link:hover {
  text-decoration: underline;
}


.invoice-disclaimer {
  font-size: .95rem; /* Slightly larger for better readability */
  color: #3a506b; /* A refined blue shade */
  background: #f0f4f8; /* Soft background for emphasis */
  padding: 12px 20px; /* Add some padding for a cleaner look */
  border-radius: 8px; /* Rounded corners for a smooth, modern feel */
  margin-bottom: 20px; /* Increase space below for breathing room */
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif; /* Apple-like font stack */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  line-height: 1.5; /* Improved readability */
}

.invoice-disclaimer strong {
  color: #d9534f; /* Red color to make the "Important" text stand out */
}

.invoice-disclaimer a.contact-link {
  color: #007bff; /* Link color that stands out */
  text-decoration: underline;
}

.invoice-disclaimer a.contact-link:hover {
  text-decoration: none;
  color: #0056b3; /* Slightly darker blue on hover */
}


/* Summary and Payment Popup Styles */
#summary-payment-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 30px;
  z-index: 1000;
  max-width: 600px;
  width: 90%;
  display: none; /* Initially hidden */
  overflow-y: auto; /* Allow scrolling within the popup */
  max-height: 90%; /* Ensure the popup does not exceed the viewport height */
}

#summary-payment-popup.active {
  display: block;
}

.payment-section {
  margin-top: 20px;
}

#payment-element {
  display: block;
  min-height: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#payment-message {
  color: #dc3545;
  font-size: 1rem;
  margin-top: 10px;
  text-align: center;
  display: none;
}

#payment-message.hidden {
  display: none;
}

/* Support Section */
.support-section {
  margin-top: 50px;
}

.support-section h2 {
  font-size: 2.5rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center;
}

.support-box {
  background: none; /* Remove white background */
  padding: 0; /* Remove padding */
  box-shadow: none; /* Remove card shadow effect */
  text-align: center;
  margin: 0 auto;
}

.support-box h3 {
  font-size: 1.75rem;
  color: #3a506b;
  margin-bottom: 10px;
}

.support-box p {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 20px;
}

/* Dropdown Styles */
.support-form label {
  display: block;
  font-size: 1rem;
  color: #3a506b;
  margin-bottom: 8px;
  text-align: left;
}

.support-form .dropdown {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: 2px solid #d4af37;
  font-size: 1rem;
  margin-bottom: 20px;
  background-color: #faf9f6;
  color: #333;
}

.support-form .claim-number-input {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: 2px solid #d4af37;
  font-size: 1rem;
  margin-bottom: 20px;
  background-color: #faf9f6;
  color: #333;
}

.support-form .claim-number-input::placeholder {
  color: #888;
}

.support-form textarea {
  width: 100%;
  padding: 15px;
  border: 2px solid #d4af37;
  border-radius: 10px;
  font-size: 1rem;
  color: #333333;
  background-color: #faf9f6;
  margin-bottom: 20px;
  transition: border-color 0.3s ease;
}

.support-form textarea:focus {
  border-color: #f9d342;
  outline: none;
}

.support-form button {
  background: #d4af37;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 20px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.support-form button:hover {
  background: #b89c2a;
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

.support-form .email-input {
  width: 80%;
  padding: 12px;
  border-radius: 10px;
  border: 2px solid #d4af37;
  font-size: 1rem;
  margin-bottom: 20px;
  background-color: #faf9f6;
  color: #333;
}

.support-form .email-input::placeholder {
  color: #888;
}

.support-form .email-input:focus {
  border-color: #f9d342;
  outline: none;
}





/* Footer Section */
.footer {
  background: #ffffff;
  color: #333333;
  padding: 40px 0;
  text-align: center;
  font-size: 1rem;
  border-top: 2px solid #e5e5e5;
  margin-top: auto; /* Allows footer to push to the bottom */
  margin-bottom: -55px;
}

.footer p {
  margin-bottom: 10px;
  color: #555555;
}

.footer a {
  color: #d4af37;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #b89c2a;
}

/* Payment Section Styles */
#payment-element {
  display: block;
  margin: 20px 0;
  padding: 10px;
  border: 1px solid #d4af37;
  border-radius: 8px;
  background-color: #faf9f6;
}

#payment-message {
  color: red;
  font-size: 1rem;
  margin-top: 10px;
}

/* Specific Receipt Modal Styles */
.receipt-modal {
  max-width: 600px;
  padding: 30px;
  background-color: #faf9f6;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.receipt-content {
  position: relative;
  padding: 20px;
}

.receipt-content h2 {
  font-size: 1.8rem;
  color: #3a506b;
  text-align: center;
  margin-bottom: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.receipt-details {
  margin-bottom: 20px;
  border-top: 2px solid #d4af37;
  padding-top: 15px;
}

.receipt-details p {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 8px;
}

.payment-summary {
  font-size: 1.2rem;
  color: #d4af37;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.stripe-section {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #d4af37;
  border-radius: 8px;
  background-color: #f7f7f7;
}

.terms-conditions {
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 8px;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.terms-label {
  font-size: 1rem;
  color: #3a506b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.terms-link {
  color: #d4af37;
  text-decoration: none;
  font-weight: 500;
}

.terms-link:hover {
  color: #b89c2a;
  text-decoration: underline;
}

.payment-disclaimer {
  margin-top: 20px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 5px;
  font-size: 0.9rem;
  color: #555;
  text-align: center;
}

.payment-disclaimer p {
  margin: 0;
  line-height: 1.4;
}

/* Adjust button for better spacing */
#summary-payment-popup .btn-submit-claim {
  display: block;
  margin: 20px auto;
  background-color: #d4af37;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  cursor: pointer;
  border-radius: 8px;
}

#summary-payment-popup .btn-submit-claim:hover {
  background-color: #b89c2a;
}

.overlay.active {
  background-color: rgba(0, 0, 0, 0.6);
}


/* Button styling for Stripe payment */
.btn-submit-payment {
  background-color: #d4af37; /* Your branding color */
  color: #ffffff;
  padding: 12px 24px;
  font-size: 1.1rem;
  border-radius: 10px;
  border: 2px solid transparent;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.btn-submit-payment:hover {
  background-color: #b89c2a; /* Darker gold on hover */
  transform: scale(1.05); /* Slight scale on hover */
}

.btn-submit-payment:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Scrollbar styles for payment form and receipt modal */
.payment-form, .receipt-modal {
  overflow-y: auto; /* Enables scrolling if the content overflows */
  max-height: 80vh; /* Ensures the modal is not too tall */
}

.payment-form::-webkit-scrollbar, .receipt-modal::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.payment-form::-webkit-scrollbar-track, .receipt-modal::-webkit-scrollbar-track {
  background: #f9f9f9; /* Light background for the track */
  border-radius: 10px;
}

.payment-form::-webkit-scrollbar-thumb, .receipt-modal::-webkit-scrollbar-thumb {
  background-color: #FFD700; /* Gold/yellow color for the scrollbar */
  border-radius: 10px; /* Rounded corners for the scrollbar */
  border: 3px solid #f9f9f9; /* Padding inside the scrollbar to match the track */
}

.payment-form::-webkit-scrollbar-thumb:hover, .receipt-modal::-webkit-scrollbar-thumb:hover {
  background-color: #FFC700; /* Slightly darker yellow on hover */
}

/* For Firefox */
.payment-form, .receipt-modal {
  scrollbar-width: thin; /* Makes scrollbar thinner */
  scrollbar-color: #FFD700 #f9f9f9; /* Thumb color / Track color */
}


.payment-form {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the form */
  width: 100%;
  max-width: 500px; /* Increase the width of the payment form */
  margin: 0 auto; /* Center the form on the page */
  padding: 20px;
  background-color: #f9f9f9; /* Optional background to highlight the form */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.pay-button {
  background-color: #007BFF; /* Change to a blue color */
  color: white;
  font-size: 18px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
  width: 100%; /* Make button responsive */
  max-width: 300px; /* Limit max width */
}

.pay-button:hover {
  background-color: #0056b3; /* Darker shade of blue on hover */
}

.pay-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}

#payment-element {
  width: 100%; /* Ensure the payment element takes full width */
  max-width: 500px; /* Match the form width */
}

/* Custom Alert Styles */
.custom-alert {
  width: calc(100% - 40px); /* Leaves space on the sides */
  margin: 20px auto; /* Centers the alert vertically */
  background-color: #28a745; /* Green color for success */
  color: #ffffff; /* White text color */
  padding: 15px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeInDown 0.5s ease-in-out;
}

.custom-alert.success-alert {
  background-color: #28a745; /* Reaffirm green for success */
}

.custom-alert p {
  margin: 0;
  font-size: 1.1rem;
}

.close-alert-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
  transition: color 0.3s ease;
}

.close-alert-btn:hover {
  color: #cccccc;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* User Info Box Styling */
.user-info-box {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  text-align: center;
}

.user-info-box h4 {
  font-size: 1.4rem;
  color: #3a506b;
  margin-bottom: 5px;
  font-weight: bold;
}

.user-info-box p {
  font-size: 1.1rem;
  color: #555555;
  margin: 0;
  font-style: italic;
}


/* Sidebar Styles */
.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #f4f4f4;
  box-shadow: -2px 0 12px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.sidebar.active {
  right: 0;
}

.sidebar-header {
  text-align: center;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.sidebar-content {
  text-align: center;
}

.sidebar-content p {
  font-size: 1.1rem;
  color: #333;
  margin-bottom: 10px;
}

.sidebar-footer {
  text-align: center;
}

.btn-logout {
  background-color: #d4af37;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-logout:hover {
  background-color: #b89c2a;
}

/* Overlay for greying out the background */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Greying effect */
  z-index: 1000; /* Higher than other elements */
  display: none;
}

.overlay.active {
  display: block;
}

.overlay,
.overlay.active {
  transition: opacity 0.3s ease;
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

.overlay {
  opacity: 0;
  visibility: hidden;
}


.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.close-sidebar-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #3a506b;
  color: #ffffff;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.close-sidebar-btn:hover {
  background: #d4af37;
  transform: scale(1.1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

/* General Styles for Success and Error Messages */
.message {
  position: relative;
  top: 25px;
  padding: 12px 18px;
  border-radius: 8px;
  font-weight: 600;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  text-align: center;
  margin: 20px auto;  /* Centering the message */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  max-width: 400px; /* Limiting the width */
  width: 100%; /* Ensures the box is responsive */
}


/* Subtle fade-in animation for smoothness */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Success Message */
.success-message {
  background-color: rgba(76, 217, 100, 0.1); /* Soft, translucent green */
  border: 1px solid rgba(76, 217, 100, 0.6);  /* Subtle green border */
  color: #4cd964; /* Apple's signature green */
}

/* Error Message */
.error-message {
  background-color: rgba(255, 59, 48, 0.1);  /* Soft, translucent red */
  border: 1px solid rgba(255, 59, 48, 0.6);  /* Subtle red border */
  color: #ff3b30;  /* Apple's signature red */
}

/* Optional: Adding hover effect for a bit of interaction */
.message:hover {
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.15);
}

.bondsman-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Slightly darker for enhanced contrast */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  backdrop-filter: blur(4px); /* Adds a subtle blur for elegance */
}

.bondsman-modal-content {
  position: relative;
  background: #f9f9f9; /* Softer white for a more inviting look */
  padding: 30px;
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15); /* Adds a shadow for depth */
  color: #333;
  font-family: 'Helvetica Neue', sans-serif; /* Clean font style */
  text-align: left;
  animation: fadeIn 0.3s ease-out; /* Smooth fade-in animation */
}

.bondsman-modal-content h2 {
  font-size: 1.8rem;
  font-weight: 600;
  color: #2c3e50; /* Stronger contrast color for the heading */
  margin-bottom: 15px;
}

.bondsman-modal-content p {
  font-size: 1rem;
  margin-bottom: 8px;
}

.bondsman-modal-content p strong {
  color: #2c3e50; /* Darker color for labels */
}

.bondsman-modal-content button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.bondsman-modal-content button:hover {
  color: #2c3e50; /* Changes color on hover for interactivity */
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}




/* Claims Overview Popup - Mobile Responsive */
@media (max-width: 768px) {
  /* Claims Grid Layout */
  .claims-grid {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
  }

  /* Overview Claims Row */
  .claims-row {
    width: 100%;
    margin-bottom: 15px;
    padding: 15px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
  }

  .claims-row .claim-column {
    width: 100%;
    padding: 8px 0;
  }

  .claim-popup-id {
    color: #333;
    font-size: 1.15rem;
    font-weight: 600;
    margin-bottom: 8px;
  }

  /* Overview Files Section */
  .claims-row .claim-file-list {
    max-height: 150px;
    overflow-y: auto;
    padding-right: 10px;
  }

  .claims-row .claim-file-item {
    padding: 8px;
    margin-bottom: 5px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(200, 200, 200, 0.5);
  }

  /* Overview Notice Icon */
  .claim-popup-notice-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }

  /* Overview Remarks Section */
  .popup-claim-remarks-section {
    position: relative;
    width: 100%;
    margin: 10px 0;
    padding: 12px;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
}

/* Quick View Claims Section - Mobile Responsive */
@media (max-width: 425px) {
  /* Quick View Section Layout */
  .claims-section {
    margin-top: 30px;
    padding: 0 10px;
  }

  .claims-header {
    margin-bottom: 30px;
  }

  .claims-header h2 {
    font-size: 1.8rem;
  }

  .show-more-container {
    position: static;
    margin-top: 10px;
  }

  /* Quick View Individual Claims */
  .claims-list {
    gap: 15px;
  }

  .claim-item {
    padding: 15px;
    min-height: auto;
    margin-bottom: 15px;
  }

  .claim-info {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .claim-details {
    padding-bottom: 40px;
  }

  .claim-details h4 {
    font-size: 1.2rem;
    margin-bottom: 8px;
  }

  .claim-details p {
    font-size: 0.9rem;
    margin-bottom: 5px;
    line-height: 1.4;
  }

  /* Quick View Status */
  .claim-status {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    margin: 5px 0;
    font-size: 0.85rem;
  }

  /* Quick View Files Section */
  .file-list {
    min-width: auto;
    max-width: 100%;
    margin-top: 10px;
  }

  .file-list p {
    font-size: 1rem;
    text-align: left;
    padding-bottom: 5px;
  }

  .file-list ul {
    margin-top: 8px;
  }

  .file-list ul li {
    font-size: 0.85rem;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* Quick View Notice Icon */
  .claim-notice-icon {
    width: 40px;
    height: 40px;
    bottom: -8px;
    left: -5px;
  }

  /* Quick View Remarks Section */
  .claim-remarks-section {
    margin-left: 45px;
    margin-top: 5px;
    padding: 10px;
    font-size: 0.85rem;
  }

  .remark-label {
    font-size: 0.9rem;
    margin-bottom: 4px;
  }

  .remark-text {
    font-size: 0.85rem;
    line-height: 1.4;
  }

  .remark-navigation {
    margin-top: 8px;
    gap: 8px;
  }

  .remark-navigation button {
    padding: 4px 8px;
    font-size: 0.8rem;
  }
}

/* Even Smaller Screens */
@media (max-width: 375px) {
  /* Claims Overview Popup Adjustments */
  #claims-overview-popup {
    width: 95%;
    padding: 15px;
    max-height: 90vh;
  }

  .claims-popup-content {
    padding: 10px;
  }

  .claims-header-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  .claims-row {
    padding: 12px;
    margin-bottom: 12px;
  }

  .claims-row .claim-file-item {
    font-size: 0.85rem;
    padding: 6px;
  }

  .claim-popup-notice-icon {
    width: 32px;
    height: 32px;
  }

  /* Quick View Adjustments */
  .claim-item {
    padding: 12px;
  }

  .claim-details h4 {
    font-size: 1.1rem;
  }

  .claim-details p {
    font-size: 0.85rem;
  }

  .file-list p {
    font-size: 0.95rem;
  }

  .file-list ul li {
    font-size: 0.8rem;
  }

  .btn-upload-file {
    font-size: 0.8rem;
    padding: 6px;
  }

  .claim-notice-icon {
    width: 35px;
    height: 35px;
  }

  .claim-remarks-section {
    margin-left: 40px;
    font-size: 0.8rem;
  }

  .remark-navigation button {
    padding: 3px 6px;
    font-size: 0.75rem;
  }

  /* Shared Button Styles */
  .btn-upload-file {
    width: 100%;
    padding: 8px;
    font-size: 0.85rem;
    margin-top: 8px;
  }
}