.terms-page {
    background: #faf9f6;
    color: #3a506b;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    padding: 40px 20px;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .terms-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-header h1 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .terms-toc {
    margin: 20px 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Split into two columns for a balanced look */
    gap: 10px; /* Space between items */
  }
  
  .terms-toc li {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 8px 15px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .terms-toc a {
    flex-grow: 1;
    color: #3a506b;
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    transition: color 0.3s ease, transform 0.2s ease;
  }
  
  .terms-toc a:hover {
    color: #d4af37;
    transform: translateX(5px); /* Slight movement to the right */
  }
  
  .terms-toc li:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    background: #f9f9f9; /* Slightly lighter background on hover */
  }
  
  .terms-toc li::before {
    content: "•"; /* Custom bullet point */
    color: #d4af37;
    font-size: 1.2rem;
    margin-right: 10px;
    transition: transform 0.3s ease;
  }
  
  .terms-toc li:hover::before {
    transform: scale(1.2);
  }
  
  
  .terms-header p {
    font-size: 0.9rem;
    color: #555;
  }
  
  .terms-content {
    line-height: 1.6;
  }
  
  .terms-content h2 {
    font-size: 1.3rem;
    color: #3a506b;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .terms-content p,
  .terms-content ul {
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
  .terms-content ul {
    padding-left: 20px;
    list-style: disc;
  }
  
  .terms-content ul li {
    margin-bottom: 8px;
  }
  
  .terms-footer {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #d4af37;
    padding-top: 10px;
  }
  
  .terms-footer p {
    font-size: 0.9rem;
    color: #555;
  }
  
  .terms-footer a {
    color: #d4af37;
    text-decoration: none;
  }
  
  .terms-footer a:hover {
    text-decoration: underline;
  }
  