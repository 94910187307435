.header-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 20px;
}

.header {
  background: #3a506b;
  color: #ffffff;
  padding: 15px 0;
  border-bottom: 2px solid #d4af37;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: -20px;
}

.logo-image {
  width: 80px;
  height: auto;
  margin-right: 10px;
}

.logo-container h1 {
  font-size: 1.5rem;
  color: #d4af37;
  margin: 0;
}

.navbar {
  flex-grow: 1;
  text-align: center;
}

.navbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navbar ul li {
  margin: 0 15px;
}

.navbar ul li a {
  color: #ffffff;
  text-decoration: none;
  padding: 8px 15px;
  font-size: 1.1rem;
}

.navbar ul li a:hover {
  color: #f9d342;
}

.account-icon-container {
  display: flex;
  align-items: center;
  margin-right: -20px;
}

.login-text {
  font-size: 1.3rem;
  font-weight: 600;
  cursor: pointer;
  color: #ffffff;
  margin-right: 10px;
}

.account-icon {
  width: 75px;
  height: auto;
  cursor: pointer;
}

/* Enhanced Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  height: 100vh;
  width: 300px;
  background: linear-gradient(180deg, #3a506b 0%, #2c3e50 100%);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 0;
  transition: right 0.3s ease;
  box-shadow: -4px 0 20px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.sidebar.active {
  right: 0;
}

.sidebar-header {
  padding: 20px;
  background: rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0;
  text-align: center;
}

.close-sidebar-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  transition: color 0.2s;
  padding: 8px;
  border-radius: 50%;
}

.close-sidebar-btn:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
}

.sidebar-profile-img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  object-fit: cover;
}

.sidebar h2 {
  font-size: 1.3rem;
  color: #ffffff;
  margin: 10px 0 5px;
  font-weight: 600;
}

.sidebar-email {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  transition: color 0.2s;
}

.sidebar-email:hover {
  color: #f9d342;
}

.sidebar-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  width: 100%;
}

.sidebar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-nav li {
  margin: 8px 0;
}

.sidebar-nav li a {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
  font-size: 1.1rem;
  padding: 10px 16px;
  border-radius: 8px;
  display: block;
  transition: all 0.2s ease;
  cursor: pointer;
}

.sidebar-nav li a:hover {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.1);
  padding-left: 20px;
}

.sidebar-nav .file-claim-link {
  background: linear-gradient(145deg, #f9d342, #d4af37);
  color: #3a506b !important;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 14px 28px;
  margin: 10px auto 30px;
  border-radius: 16px;
  display: block;
  width: 85%;
  border: none;
  box-shadow: 0 4px 15px rgba(212, 175, 55, 0.25);
  transition: all 0.3s ease;
  text-align: center;
  letter-spacing: 0.5px;
}

.sidebar-nav .file-claim-link:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(212, 175, 55, 0.35);
  background: linear-gradient(145deg, #ffdc4e, #e5bc3c);
}

.sidebar-nav .file-claim-link:active {
  transform: translateY(1px);
  box-shadow: 0 2px 8px rgba(212, 175, 55, 0.2);
}

.sidebar-footer {
  padding: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.btn-logout, .btn-login {
  width: 100%;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: transparent;
  color: #ffffff;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-logout:hover, .btn-login:hover {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.3);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.hide-on-mobile {
  display: none;
}

/* Media Queries */
@media (max-width: 768px) {
  .header-flex {
    flex-direction: row;
    padding: 0 15px;
  }

  .logo-container {
    margin-left: -15px;
  }

  .logo-image {
    width: 60px;
  }

  .logo-container h1 {
    font-size: 1.3rem;
  }

  .navbar ul {
    display: none;
  }

  .account-icon-container {
    margin-right: -15px;
  }

  .login-text {
    font-size: 1.1rem;
  }

  .account-icon {
    width: 60px;
  }

  .sidebar {
    width: 100%;
    right: -100%;
  }

  .sidebar.active {
    right: 0;
  }

  .sidebar-profile-img {
    width: 120px;
    height: 120px;
  }

  .sidebar h2 {
    font-size: 1.1rem;
  }

  .sidebar-content p {
    font-size: 1rem;
  }

  .sidebar-nav .file-claim-link {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .header-flex {
    padding: 0 10px;
  }

  .logo-container {
    margin-left: -10px;
  }

  .logo-container h1 {
    font-size: 1rem;
  }

  .logo-image {
    width: 50px;
    margin-right: 5px;
  }

  .navbar ul {
    display: none;
  }

  .account-icon-container {
    margin-right: -10px;
  }

  .login-text {
    font-size: 1rem;
    margin-right: 0;
  }

  .account-icon {
    width: 50px;
  }

  .sidebar-profile-img {
    width: 90px;
    height: 90px;
  }

  .sidebar h2 {
    font-size: 1rem;
  }

  .sidebar-content p {
    font-size: 0.9rem;
  }
}

@media (max-width: 425px) {
  .login-text {
    display: none;
  }
}

@media (max-width: 1024px) {
  .header-flex {
    padding: 0 15px;
  }

  .logo-container {
    margin-left: -15px;
    flex: 0 1 auto;
  }

  .logo-image {
    width: 60px;
    margin-right: 8px;
  }

  .logo-container h1 {
    font-size: 1.3rem;
    white-space: nowrap;
  }

  .navbar {
    flex: 1;
  }

  .navbar ul {
    gap: 10px;
  }

  .navbar ul li {
    margin: 0;
  }

  .navbar ul li a {
    font-size: 1rem;
    padding: 6px 10px;
  }

  .account-icon-container {
    margin-right: -15px;
    flex: 0 1 auto;
    gap: 5px;
  }

  .login-text {
    font-size: 1.3rem;
    margin-right: 5px;
  }

  .account-icon {
    width: 72px;
  }
}

@media (min-width: 1025px) {
  .header-flex {
    padding: 0 40px;
  }

  .logo-container {
    flex: 0 1 auto;
    margin-left: -40px;
  }

  .logo-image {
    width: 60px;
    margin-right: 8px;
  }

  .logo-container h1 {
    font-size: 1.3rem;
    white-space: nowrap;
  }

  .navbar {
    flex: 1;
  }

  .navbar ul {
    gap: 10px;
  }

  .navbar ul li {
    margin: 0;
  }

  .navbar ul li a {
    font-size: 1rem;
    padding: 6px 10px;
  }

  .account-icon-container {
    flex: 0 1 auto;
    gap: 5px;
    margin-right: -40px;
  }

  .login-text {
    font-size: 1.3rem;
    margin-right: 5px;
  }

  .account-icon {
    width: 72px;
  }
}

@media (min-width: 1300px) and (max-width: 2559px) {
  .header-flex {
    padding: 0 50px;
  }

  .logo-container {
    margin-left: -50px;
  }

  .account-icon-container {
    margin-right: -50px;
  }
}

@media (min-width: 2560px) {
  .header-flex {
    padding: 0 60px;
  }

  .logo-container {
    margin-left: -600px;
  }

  .account-icon-container {
    margin-right: -600px;
  }
}