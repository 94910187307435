html {
  scroll-behavior: smooth;
}

/* Custom Scrollbar Styling */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #faf9f6;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #d4af37;
  border-radius: 10px;
  border: 3px solid #faf9f6;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342;
}

/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

body {
  line-height: 1.6;
  background-color: #faf9f6;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}



/* Support Section */
.support-section {
  padding: 80px 0;
  background-color: #faf9f6;
  color: #3a506b;
  text-align: center;
}

.support-section h2 {
  font-size: 2.5rem;
  color: #3a506b;
  margin-bottom: 20px;
}

.support-section p {
  font-size: 1.2rem;
  color: #555555;
  margin-bottom: 40px;
}

.support-form {
  max-width: 700px;
  margin: 0 auto;
  background: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  font-size: 1rem;
  color: #3a506b;
  margin-bottom: 8px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #ddd;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus {
  border-color: #d4af37;
  outline: none;
}

.btn-primary {
  background-color: #3a506b;
  color: #ffffff;
  padding: 15px 30px;
  font-size: 1.2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-top: 20px;
}

.btn-primary:hover {
  background-color: #d4af37;
  transform: scale(1.05);
}

.btn-primary:active {
  background-color: #b89c2a;
  transform: scale(0.95);
}

/* Footer Section */
.footer {
  background: #ffffff;
  color: #333333;
  padding: 40px 0;
  text-align: center;
  font-size: 1rem;
  border-top: 2px solid #e5e5e5;
}

.footer-content {
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.8;
}

.footer p {
  margin-bottom: 10px;
  color: #555555;
}

.footer a {
  color: #d4af37;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #b89c2a;
}

.footer hr {
  border: 0;
  height: 1px;
  background: #d4af37;
  margin: 20px 0;
}

.footer-social-link {
  margin: 0 10px;
  font-weight: 600;
  color: #d4af37;
}

.footer p small {
  font-size: 0.875rem;
  color: #555555;
}

.footer p small a {
  color: #d4af37;
  font-weight: bold;
}

.footer p small a:hover {
  color: #b89c2a;
}

.footer .disclaimer {
  color: #555555;
  font-size: 0.85rem;
  margin-top: 10px;
  line-height: 1.4;
}

.footer .disclaimer small {
  display: block;
  color: #777777;
}

/*
@media (min-width: 1024px) {
  .header-flex {
    justify-content: space-between;
    align-items: center;
  }

  .logo-container {
    flex: 1; 
    display: flex;
    justify-content: flex-start; 
    align-items: center;
    margin-left: -340px; 

  .navbar {
    flex: 2; 
    display: flex;
    justify-content: center; 
    align-items: center;
    margin-right: -100px;
  }

  .account-icon-container {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-right: -240px;
  }

  .logo-image {
    width: 120px; 
    height: auto;
  }

  .logo-container h1 {
    font-size: 1.7rem; 
    color: #d4af37; 
  }

  .account-icon {
    width: 110px;
    height: auto;
  }
}
*/