html {
  scroll-behavior: smooth;
}

/* Custom Scrollbar Styling */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #faf9f6;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #d4af37;
  border-radius: 10px;
  border: 3px solid #faf9f6;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342;
}

/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

body {
  line-height: 1.6;
  background-color: #faf9f6;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}


/* FAQ Section */
.faq-section {
  padding: 80px 0;
  background-color: #faf9f6;
  color: #3a506b;
}

.faq-section h2 {
  font-size: 2.5rem;
  color: #3a506b;
  text-align: center;
  margin-bottom: 50px;
}

.faq-content {
  max-width: 900px;
  margin: 0 auto;
}

.faq-item {
  margin-bottom: 40px;
}

.faq-item h3 {
  font-size: 1.75rem;
  color: #d4af37;
  margin-bottom: 15px;
}

.faq-item p {
  font-size: 1.2rem;
  color: #555555;
  line-height: 1.8;
}

.faq-item p.important {
  font-weight: bold;
}

#service-info {
  padding-top: 150px;
  margin-top: -100px;
}

#file-claim-info {
  padding-top: 150px;
  margin-top: -100px;
}

#claim-assistance-info {
  padding-top: 150px;
  margin-top: -100px;
}

.terms-and-conditions-link {
  text-align: center;
  margin-top: 30px;
  padding: 20px 0;
  border-top: 1px solid #ddd;
}

.terms-and-conditions-link p {
  font-size: 1.1em;
  color: #555;
}

.terms-and-conditions-link .terms-link:hover {
  color: #00509e;
  transition: color 0.3s ease;
}


/* Footer Section */
.footer {
  background: #ffffff;
  color: #333333;
  padding: 40px 0;
  text-align: center;
  font-size: 1rem;
  border-top: 2px solid #e5e5e5;
}

.footer-content {
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.8;
}

.footer p {
  margin-bottom: 10px;
  color: #555555;
}

.footer a {
  color: #d4af37;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #b89c2a;
}

.footer hr {
  border: 0;
  height: 1px;
  background: #d4af37;
  margin: 20px 0;
}

.footer-social-link {
  margin: 0 10px;
  font-weight: 600;
  color: #d4af37;
}

.footer p small {
  font-size: 0.875rem;
  color: #555555;
}

.footer p small a {
  color: #d4af37;
  font-weight: bold;
}

.footer p small a:hover {
  color: #b89c2a;
}

.footer .disclaimer {
  color: #555555;
  font-size: 0.85rem;
  margin-top: 10px;
  line-height: 1.4;
}

.footer .disclaimer small {
  display: block;
  color: #777777;
}


/* Media Queries for Larger Screens */
/*
@media (min-width: 1024px) {
  .header-flex {
    justify-content: space-between;
    align-items: center;
  }

  .logo-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: -340px;
  }

  .navbar {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -100px;
  }

  .account-icon-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: -240px;
  }

  .logo-image {
    width: 120px;
    height: auto;
  }

  .logo-container h1 {
    font-size: 1.7rem;
    color: #d4af37;
  }

  .account-icon {
    width: 110px;
    height: auto;
  }

  .hero-img {
    max-width: 40%;
  }

  .hero-banner {
    display: none;
  }

  .hero-text-box {
    display: block;
    margin-left: 100px;
  }

  .hero-middle {
    display: none;
  }

  .hero-bottom {
    font-size: 1.5rem;
    margin-bottom: 140px;
  }

  .hero-bottom p::after {
    width: 30%;
    height: 4px;
  }
}
*/

/* Media Query for Smaller Screens */
@media (max-width: 425px) {
  .faq-section h2 {
    font-size: 1.5rem; /* Adjust to the desired size for smaller screens */
    margin-bottom: -50px;
  }
}