/* Custom Scrollbar Styling */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #faf9f6;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #d4af37;
  border-radius: 10px;
  border: 3px solid #faf9f6;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342;
}

/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

body {
  line-height: 1.6;
  background-color: #faf9f6;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Hero Section */
.hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  padding: 0 20px;
}

/* Main Hero Container */
.hero-top {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #faf9f6;
  padding: 20px;
  text-align: center;
}

/* Hero Video Container */
.hero-video-container {
  position: relative;
  width: 50%;
  height: 400px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}



.video-overlay:hover {
  background: rgba(0, 0, 0, 0.5);
}

.play-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  transition: transform 0.3s ease;
}

.play-button:hover {
  transform: scale(1.1);
}

.play-button svg {
  width: 64px;
  height: 64px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
}

.overlay-text {
  color: white;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 500;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

/* Show controls on hover */
.hero-video::-webkit-media-controls-panel {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hero-video:hover::-webkit-media-controls-panel {
  opacity: 1;
}

/* Text Box Styling */
.hero-text-box {
  max-width: 90%;
  color: #3a506b;
  font-size: 1.2rem;
  line-height: 1.5;
}

.hero-text-box h2 {
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.hero-text-box p {
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 15px;
  color: #444444;
  line-height: 1.8;
}

/* Buttons */
.hero-buttons {
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

/* Hero Buttons - Alignment Fix */
.hero-buttons .btn-primary,
.hero-buttons .btn-secondary {
  display: inline-block;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
  border: 2px solid #d4af37;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  vertical-align: middle;
  margin: 0;
}

.hero-buttons .btn-primary {
  background: #d4af37;
}

.hero-buttons .btn-primary:hover {
  background: #b89c2a;
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
  transform: scale(1.05);
}

.hero-buttons .btn-secondary {
  background: #555555;
}

.hero-buttons .btn-secondary:hover {
  background: #333333;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
  transform: scale(1.05);
}

/* Hero Bottom Text */
.hero-bottom {
  background: none;
  color: #000000;
  padding: 20px;
  text-align: center;
  font-size: 1.2rem;
  margin-top: -20px;
  font-weight: 600;
  line-height: 1.6;
}

.hero-bottom p::after {
  content: "";
  display: block;
  width: 50%;
  height: 3px;
  background: #d4af37;
  margin: 10px auto 0;
}

/* About Us Section */
.about {
  padding: 80px 0;
  background-color: #faf9f6;
  text-align: center;
  color: #3a506b;
  line-height: 2;
}

.about h2 {
  font-size: 2.5rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center;
}

.about-content {
  max-width: 900px;
  margin: 0 auto;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  line-height: 2;
  color: #555555;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
}

.about-image {
  width: 60%;
  height: 480px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.about-image:hover {
  transform: scale(1.02);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.about-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(1.05) contrast(1.1);
  transition: filter 0.3s ease;
}

.about-img:hover {
  filter: brightness(1.15) contrast(1.15);
}

.about-content p {
  font-size: 1.2rem;
  margin-bottom: 25px;
}

.disclaimer small {
  color: #777777;
  font-size: 0.95rem;
  line-height: 1.6;
  display: block;
  margin-top: 15px;
}

/* Pricing Section */
.pricing {
  padding: 70px 0;
  background-color: #faf9f6;
  text-align: center;
  color: #3a506b;
  line-height: 1.8;
}

.pricing h2 {
  font-size: 2.5rem;
  color: #3a506b;
  margin-bottom: 40px;
}

.pricing-card {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.pricing-card h3 {
  font-size: 1.75rem;
  color: #3a506b;
  margin-bottom: 20px;
}

.pricing-card p {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555555;
}

.price-amount {
  font-size: 3rem;
  font-weight: bold;
  color: #d4af37;
  margin-top: 20px;
  text-align: center;
}

.btn-file-claim {
  display: inline-block;
  background: #3a506b;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #d4af37;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
  margin-top: 20px;
}

.btn-file-claim:hover {
  background: #d4af37;
  color: #3a506b;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(212, 175, 55, 0.3);
}

.btn-file-claim:active {
  background: #b89c2a;
  transform: scale(0.95);
  box-shadow: 0 6px 10px rgba(216, 174, 42, 0.2);
}

/* Services Section */
.services {
  padding: 60px 0;
  background-color: #faf9f6;
}

.services h2 {
  font-size: 2.5rem;
  color: #3a506b;
  margin-bottom: 40px;
  text-align: center;
}

.service-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  background: #ffffff;
  padding: 30px;
  margin: 10px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 250px;
  color: #3a506b;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.15);
}

.card h3 {
  font-size: 1.75rem;
  margin-bottom: 15px;
}

.card p {
  font-size: 1rem;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #555555;
}

.btn-learn-more {
  display: inline-block;
  background: #ffffff;
  color: #d4af37;
  padding: 12px 24px;
  border-radius: 20px;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 600;
  border: 2px solid #d4af37;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.btn-learn-more:hover {
  background: #d4af37;
  color: #ffffff;
  box-shadow: 0 8px 15px rgba(212, 175, 55, 0.3);
}

/* Contact Us Section */
.contact {
  padding: 80px 0;
  background-color: #faf9f6;
  text-align: center;
  color: #3a506b;
  line-height: 2;
}

.contact h2 {
  font-size: 2.5rem;
  color: #3a506b;
  margin-bottom: 40px;
  text-align: center;
}

.contact-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  line-height: 2;
  color: #555555;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.contact-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 16px 40px rgba(0, 0, 0, 0.2);
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 15px;
  border: 2px solid #d4af37;
  border-radius: 10px;
  margin-bottom: 20px;
  font-size: 1rem;
  color: #333333;
  background-color: #faf9f6;
  transition: border-color 0.3s ease;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #f9d342;
  outline: none;
}

.contact-form button {
  background: #d4af37;
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 20px;
  font-size: 1.1rem;
  text-decoration: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
}

.contact-form button:hover {
  background: #b89c2a;
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

.contact-form button:active {
  transform: translateY(2px);
  box-shadow: 0 6px 10px rgba(216, 174, 42, 0.2);
}

/* Footer Section */
.footer {
  background: #ffffff;
  color: #333333;
  padding: 40px 0;
  text-align: center;
  font-size: 1rem;
  border-top: 2px solid #e5e5e5;
}

.footer-content {
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.8;
}

.footer p {
  margin-bottom: 10px;
  color: #555555;
}

.footer a {
  color: #d4af37;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #b89c2a;
}

.footer hr {
  border: 0;
  height: 1px;
  background: #d4af37;
  margin: 20px 0;
}

.footer-social-link {
  margin: 0 10px;
  font-weight: 600;
  color: #d4af37;
}

.footer p small {
  font-size: 0.875rem;
  color: #555555;
}

.footer p small a {
  color: #d4af37;
  font-weight: bold;
}

.footer p small a:hover {
  color: #b89c2a;
}

/* Disclaimer Styles */
.footer .disclaimer {
  color: #555555;
  font-size: 0.85rem;
  margin-top: 10px;
  line-height: 1.4;
}

.footer .disclaimer small {
  display: block;
  color: #777777;
}

/* Mobile View (max-width: 425px) */
@media (max-width: 425px) {
  .hero-text-box {
    max-width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .hero-text-box h2 {
    font-size: 1.2rem !important;
    margin-bottom: 10px;
  }

  .hero-text-box p {
    font-size: 1rem;
    line-height: 1.4;
  }

  .hero-buttons {
    gap: 10px;
    width: 100%;
  }

  .hero-buttons .btn-primary,
  .hero-buttons .btn-secondary {
    margin: auto !important;
    text-align: center !important;
    width: 90%;
    padding: 10px;
    font-size: 1rem;
  }

  .hero-buttons .btn-primary,
  .hero-buttons .btn-secondary {
    position: relative;
    left: 12px;
  }

  .hero-video-container {
    width: 100%;
    margin-bottom: 15px;
  }

  .hero-bottom {
    font-size: 0.9rem;
    line-height: 1.3;
  }

  .hero-bottom p::after {
    width: 50%;
  }

  .services {
    margin-top: 50px;
    margin-bottom: -50px;
  }

  .about-image {
    width: 100%;
  }
}

/* Adjustments for Medium Screens (Tablets and Up) */
@media (min-width: 768px) {
  .hero-top {
    flex-direction: row;
    justify-content: space-between;
  }

  .hero-text-box {
    max-width: 45%;
    text-align: left;
  }

  .hero-video-container {
    width: 45%;
    max-height: 600px;
  }

  .about-content {
    flex-direction: row;
  }

  .about-image {
    flex: 1;
    max-height: none;
  }

  .about-text {
    flex: 2;
    padding: 40px 60px;
  }

  .hero-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
  }

  .hero-buttons .btn-primary,
  .hero-buttons .btn-secondary {
    padding: 12px 30px;
    font-size: 1.1rem;
    text-align: center;
    min-width: 150px;
    position: static;
  }

  .services {
    margin-top: 120px;
  }
}

/* Adjustments for Large Screens (Desktops) */
@media (min-width: 1024px) {
  .hero-video-container {
    height: 500px;
  }

  .hero-bottom {
    font-size: 1.5rem;
    margin-bottom: 140px;
  }

  .hero-bottom p::after {
    width: 30%;
    height: 4px;
  }

  .hero-text-box h2 {
    font-size: 2rem;
  }

  .hero-buttons {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  .hero-buttons .btn-primary,
  .hero-buttons .btn-secondary {
    width: auto;
    padding: 15px 30px;
    font-size: 1.2rem;
    text-align: center;
    position: static;
  }
}

/* Adjustments for Smaller Screens (Tablets and Smaller Laptops) */
@media (max-width: 1024px) {
  .hero-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
  }

  .hero-buttons .btn-primary,
  .hero-buttons .btn-secondary {
    width: auto;
    padding: 12px 20px;
    font-size: 1.1rem;
    text-align: center;
  }

  .hero-video-container {
    width: 50%;
    max-height: 500px;
  }
}

/* Adjustments for Very Small Screens (Mobile Devices) */
@media (max-width: 768px) {
  .hero {
    margin-top: 10px;
    padding: 0 10px;
  }

  .hero-top {
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px 0;
  }

  .hero-video-container {
    width: 90%;
    height: 300px;
  }

  .play-button svg {
    width: 48px;
    height: 48px;
  }

  .overlay-text {
    font-size: 0.9rem;
  }

  .hero-text-box {
    order: 2;
    max-width: 90%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 25px;
  }

  .hero-text-box h2 {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }

  .hero-text-box p {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;
    margin-left: 0;
  }

  .hero-buttons {
    order: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 90%;
    margin: 20px 0;
  }

  .hero-buttons .btn-primary,
  .hero-buttons .btn-secondary {
    width: 80%;
    padding: 12px 15px;
    font-size: 1.1rem;
    text-align: center;
  }

  .hero-bottom {
    display: none;
  }

  .hero-bottom p::after {
    content: "";
    display: block;
    width: 60%;
    height: 2px;
    background: #d4af37;
    margin: 10px auto 0;
  }
}

/* Larger Screens (1440px and above) */
@media (min-width: 1440px) {
  .hero-buttons {
    gap: 20px;
    justify-content: flex-start;
  }

  .hero-buttons .btn-primary,
  .hero-buttons .btn-secondary {
    padding: 12px 50px;
    font-size: 1.1rem;
    border-radius: 50px;
    min-width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: background 0.3s ease, color 0.3s ease, transform 0.2s ease, box-shadow 0.2s ease;
    position: static;
  }

  .hero-buttons .btn-primary:hover,
  .hero-buttons .btn-secondary:hover {
    transform: scale(1.04);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  }

  .hero-video-container {
    height: 600px;
  }
}