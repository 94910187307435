/* Footer Section */
.footer {
    background: #ffffff; /* White background for the footer */
    color: #333333; /* Dark gray text for contrast */
    padding: 40px 0; /* Increased padding for a more spacious feel */
    text-align: center; /* Center align the text */
    font-size: 1rem; /* Standard font size for footer text */
    border-top: 2px solid #e5e5e5; /* Light gray border at the top for separation */
  }
  
  .footer-content {
    max-width: 900px;
    margin: 0 auto; /* Center the content for balanced layout */
    line-height: 1.8; /* Slightly increased line height for readability */
  }
  
  .footer p {
    margin-bottom: 10px; /* Space between paragraphs */
    color: #555555; /* Soft gray for footer text */
  }
  
  .footer a {
    color: #d4af37; /* Yellow-gold for link text */
    text-decoration: none; /* Remove underline from links */
    transition: color 0.3s ease; /* Smooth transition for link hover */
    margin-right: 5px; /* Optional spacing between phone numbers */
  }
  
  
  .footer a:hover {
    color: #b89c2a; /* Darker gold on hover */
  }
  
  .footer hr {
    border: 0;
    height: 1px;
    background: #d4af37; /* Yellow-gold line for separation */
    margin: 20px 0; /* Space above and below the line */
  }
  
  .footer-social-link {
    margin: 0 10px; /* Space between social media links */
    font-weight: 600; /* Make social links bold */
    color: #d4af37; /* Yellow-gold for social links */
  }
  
  .footer p small {
    font-size: 0.875rem; /* Smaller font size for developer credit */
    color: #555555; /* Soft gray for developer credit */
  }
  
  .footer p small a {
    color: #d4af37; /* Yellow-gold for developer link */
    font-weight: bold; /* Bold for emphasis */
  }
  
  .footer p small a:hover {
    color: #b89c2a; /* Darker gold on hover */
  }
  
  .footer .disclaimer {
    color: #555555; /* Soft gray for readability */
    font-size: 0.85rem; /* Slightly smaller font size */
    margin-top: 10px; /* Space above the disclaimer */
    line-height: 1.4; /* Adjust line spacing for readability */
  }
  
  .footer .disclaimer small {
    display: block;
    color: #777777; /* Slightly lighter gray for the disclaimer text */
  }

  
  
  /* Media Query for Smaller Screens */
@media (max-width: 425px) {
  .footer p {
    font-size: 0.875rem; /* Smaller font size for smaller screens */
  }

  .footer p a {
    font-size: 0.875rem; /* Adjust link size as well */
  }
}

@media (max-width: 375px) {
  .footer p {
    font-size: 0.775rem; /* Smaller font size for smaller screens */
  }

  .footer p a {
    font-size: 0.775rem; /* Adjust link size as well */
  }
}