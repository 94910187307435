/* Custom Scrollbar Styling */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #faf9f6;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #d4af37;
  border-radius: 10px;
  border: 3px solid #faf9f6;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342;
}

/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

body {
  line-height: 1.6;
  background-color: #faf9f6;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.alert {
  color: #fff;
  background-color: #d9534f; /* Bootstrap danger color */
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
}



/* Login Section */
.login-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  background-color: #faf9f6;
}

.login-card {
  background: #ffffff;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.login-card h2 {
  font-size: 2rem;
  color: #3a506b;
  margin-bottom: 30px;
}

.login-form input {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid #d4af37;
  border-radius: 10px;
  font-size: 1rem;
  color: #333333;
  background-color: #faf9f6;
  transition: border-color 0.3s ease;
}

.login-form input:focus {
  border-color: #f9d342;
  outline: none;
}

.btn-login {
  background: #d4af37;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
  width: 100%;
}

.btn-login:hover {
  background: #b89c2a;
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

/* Updated styling */
.login-options {
  margin-top: 20px;
  display: flex;
  flex-direction: column; /* Stack the buttons vertically */
  align-items: center; /* Center the buttons */
  gap: 10px; /* Subtle spacing between the buttons */
}

.login-options .link-button {
  background: none;
  border: none;
  color: #3a506b;
  cursor: pointer;
  font-size: 1rem;
  padding: 8px;
  transition: color 0.2s ease;
}

.login-options .link-button:hover {
  color: #d4af37;
  text-decoration: underline; /* Simple underline on hover */
}

.login-options .forgot-password {
  font-size: 1.1rem;
  font-weight: 500; /* Slightly less emphasis */
}

.login-options .create-account {
  font-size: 1.1rem;
  color: #b89c2a;
  font-weight: 500; /* Light font-weight for minimalism */
  padding: 8px 12px;
  border: 1px solid #b89c2a; /* Thinner border for a subtle look */
  transition: background-color 0.2s ease, color 0.2s ease;
}

.login-options .create-account:hover {
  background-color: rgba(184, 156, 42, 0.1); /* Subtle background color on hover */
  color: #b89c2a; /* Keep text color the same but with background contrast */
}


/* Create Account Modal */
.modal {
  display: none;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal.active {
  display: block;
}

.modal-content {
  background-color: #faf9f6;
  margin: 10% auto;
  padding: 40px;
  border: 1px solid #d4af37;
  width: 80%;
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.modal-content h2 {
  color: #3a506b;
  margin-bottom: 20px;
}

.create-account-form input {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid #d4af37;
  border-radius: 10px;
  font-size: 1rem;
  color: #333333;
  background-color: #ffffff;
  transition: border-color 0.3s ease;
}

.create-account-form input:focus {
  border-color: #f9d342;
  outline: none;
}

.btn-create-account {
  background: #d4af37;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.btn-create-account:hover {
  background: #b89c2a;
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

/* Close Button */
.close {
  color: #3a506b;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #d4af37;
  cursor: pointer;
}


/* Confirmation Code Input */
.confirmation-code-input {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid #d4af37; /* Matching the border style of other inputs */
  border-radius: 10px; /* Smooth border radius */
  font-size: 1rem;
  color: #333333;
  background-color: #faf9f6; /* Background consistent with the rest */
  transition: border-color 0.3s ease;
}

.confirmation-code-input:focus {
  border-color: #f9d342; /* Same focus color as other inputs */
  outline: none; /* Remove the default outline */
}

.btn-confirm-code {
  background: #d4af37;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 1.2rem;
  text-decoration: none;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.btn-confirm-code:hover {
  background: #b89c2a;
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}



/* Forgot Password Modal Specific Styles */
.forgot-password-modal {
  display: none;
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.forgot-password-modal.active {
  display: block;
}

.forgot-password-modal-content {
  background-color: #faf9f6;
  margin: 10% auto;
  padding: 40px;
  border: 1px solid #d4af37;
  width: 80%;
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Modal Header */
.forgot-password-modal-content h2 {
  color: #3a506b;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

/* Form Input Fields */
.forgot-password-form input,
.reset-password-form input {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 2px solid #d4af37;
  border-radius: 10px;
  font-size: 1rem;
  color: #333333;
  background-color: #faf9f6;
  transition: border-color 0.3s ease;
}

.forgot-password-form input:focus,
.reset-password-form input:focus {
  border-color: #f9d342;
  outline: none;
}

/* Submit Buttons */
.btn-forgot-password,
.btn-reset-password {
  background: #d4af37;
  color: #ffffff;
  padding: 15px 30px;
  border-radius: 25px;
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
}

.btn-forgot-password:hover,
.btn-reset-password:hover {
  background: #b89c2a;
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

/* Close Button */
.forgot-password-close {
  color: #3a506b;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.forgot-password-close:hover,
.forgot-password-close:focus {
  color: #d4af37;
  cursor: pointer;
}

/* Subtle Enhancements */
.forgot-password-modal-content input {
  background-color: #fdfdfd;
}

.forgot-password-modal-content button {
  background-color: #d4af37;
  color: #ffffff;
  border-radius: 25px;
}

.forgot-password-modal-content button:hover {
  background-color: #b89c2a;
  color: #fff;
}


/* Footer Section */
.footer {
  background: #ffffff;
  color: #333333;
  padding: 40px 0;
  text-align: center;
  font-size: 1rem;
  border-top: 2px solid #e5e5e5;
}

.footer-content {
  max-width: 900px;
  margin: 0 auto;
  line-height: 1.8;
}

.footer p {
  margin-bottom: 10px;
  color: #555555;
}

.footer a {
  color: #d4af37;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #b89c2a;
}

@media (min-width: 768px) {
  .login-card {
    padding: 50px;
  }

  .modal-content {
    padding: 50px;
  }
}

/* Adjustments for smaller screens (max-width: 425px) */
@media (max-width: 425px) {
  .login-section {
    height: auto; /* Remove fixed height for better flexibility */
    padding-top: 30px; /* Reduce top padding */
    padding-bottom: 30px; /* Optional bottom padding for balance */
  }

  .login-card {
    margin-top: 10px; /* Slight top margin for separation */
    padding: 25px; /* Adjust padding for compactness */
  }
}

/* Adjustments for smaller screens (max-width: 375px) */
@media (max-width: 375px) {
  .login-section {
    height: auto; /* Remove fixed height for better flexibility */
    padding-top: 20px; /* Add smaller padding for top spacing */
    padding-bottom: 20px; /* Optional bottom padding */
  }

  .login-card {
    margin-top: 0; /* Remove extra top margin if present */
    padding: 20px; /* Adjust padding to keep the card compact */
  }
}




/*
@media (min-width: 1024px) {
  .header-flex {
    justify-content: space-between;
    align-items: center;
  }

  .logo-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: -340px;
  }

  .navbar {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -100px;
  }

  .logo-image {
    width: 120px;
    height: auto;
  }

  .logo-container h1 {
    font-size: 1.7rem;
    color: #d4af37;
  }

  .account-icon {
    width: 110px;
    height: auto;
  }
}
*/