/* Custom Scrollbar Styling */
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: #faf9f6;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #302605;
  border-radius: 10px;
  border: 3px solid #faf9f6;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342;
}

/* Reset and Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Helvetica Neue", Arial, sans-serif;
}

body {
  line-height: 1.6;
  background-color: #faf9f6;
  color: #333;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}


/* Dashboard Styles */
.filer-dashboard {
  padding: 40px 0;
}

.welcome-section {
  text-align: center;
  margin-bottom: 40px;
}

.welcome-section h2 {
  font-size: 2.5rem;
  color: #3a506b;
  margin-bottom: 10px;
}

.welcome-section p {
  font-size: 1.2rem;
  color: #555555;
}

/* Metrics Section */
.metrics-section {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.metric-card {
  flex: 1;
  min-width: 250px;
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.metric-card h3 {
  font-size: 1.75rem;
  margin-bottom: 10px;
  color: #3a506b;
}


/* Styling for the Total Claims Count */
#total-claims-count,
#claims-in-progress-count,
#claims-resolved-count,
#claims-outstanding-count {
  font-size: 4rem;
  color: #d4af37;
  font-weight: bold;
  margin: 10px 0;
}

/* Claims Section */
.claims-section {
  margin-top: 50px;
}

.claims-section h2 {
  font-size: 2rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center;
}

.claims-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.claim-item {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.claim-item {
  background: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative; /* Allows absolute positioning of the icon */
}

.claim-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.claim-icon img {
  width: 60px; /* Adjust size as needed */
  height: auto;
  cursor: pointer; /* Changes the cursor to a pointer on hover */
  transition: transform 0.3s ease, filter 0.3s ease; /* Smooth transition for hover effects */
}

.claim-icon img:hover {
  transform: scale(1.1); /* Slightly enlarges the icon on hover */
  filter: brightness(1.2); /* Brightens the icon on hover */
}

.claim-details {
  flex: 1;
}

.claim-item h4 {
  font-size: 1.5rem;
  color: #3a506b;
  margin-bottom: 10px;
}

.claim-status {
  font-weight: bold;
  color: #dc3545;
}

.file-list {
  margin-top: 20px;
}

.file-list p {
  font-size: 1.2rem;
  font-weight: bold;
  color: #3a506b;
  margin-bottom: 10px;
  border-bottom: 2px solid #d4af37;
  padding-bottom: 5px;
}

.file-list ul {
  list-style-type: none;
  padding-left: 0;
  font-size: 0.95rem;
  color: #555555;
}

.file-list ul li {
  margin-bottom: 8px;
  transition: color 0.3s ease;
}

.file-list a {
  color: #3a506b;
  text-decoration: none;
  transition: color 0.3s ease;
  font-weight: 500;
}

.file-list a:hover {
  color: #d4af37;
  font-weight: 600;
}

.claim-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 15px;
}

.claim-actions button {
  background: #d4af37;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

.claim-actions button:hover {
  background: #b89c2a;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(216, 174, 42, 0.3);
}

.metric-card p{
  font-size: 3rem;
  font-weight: bold;
  color: #d4af37;
  margin: 5px 0;
}

.metric-card p:hover {
  color: #b89c2a; /* A slightly deeper shade of gold for hover effect */
  transform: scale(1.02); /* Subtle zoom effect on hover for a dynamic feel */
}

/* Dropdown Menu Styling */
.status-dropdown {
  display: none; /* Hidden by default */
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
  margin-top: -265px; /* Adjusts the distance below the button */
  padding: 10px 0;
  width: 150px; /* Retains the dropdown's original width */
  left: 51.5%; /* Aligns it relative to the button's horizontal center */
  transform: translateX(-50%); /* Centers the dropdown horizontally */
}

/* When the dropdown is active, show it */
.status-dropdown.active {
  display: block;
}


.status-dropdown.active {
  display: block; /* Show when the dropdown is active */
}

.status-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.status-dropdown li {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  color: #3a506b;
}

.status-dropdown li:hover {
  background-color: #f9d342;
  color: #ffffff;
}

/* Specific Button Styles */
.btn-message-user {
  background: #3a506b;
}

.btn-message-user:hover {
  background: #2a3b5b;
}

.btn-mark-completed {
  background: #1db954 !important; /* Eloquent green color */
  color: #ffffff !important;
}

.btn-mark-completed:hover {
  background: #1aae43 !important; /* Slightly darker shade for hover effect */
}

.btn-change-status {
  background: #dc3545;
}

.btn-change-status:hover {
  background: #c82333;
}

/* Grid Layout for Outstanding Claims */
.claims-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.claims-row {
  background: #faf9f6;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.claims-row:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.claim-column {
  margin-bottom: 10px;
}

.claim-column p {
  margin: 0 0 10px;
  color: #333;
  font-size: 1rem;
}

.claim-column strong {
  color: #3a506b;
  font-weight: 600;
}

/* Pickup Claim Button */
.btn-pickup-claim {
  background: #3a506b;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin: 20px auto 0; /* Center the button horizontally */
  max-width: 200px; /* Limit the button width */
  text-align: center;
}

.btn-pickup-claim:hover {
  background: #2a3b5b;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(58, 80, 107, 0.3);
}

.btn-pickup-claim:disabled {
  background: #b89c2a;
  cursor: not-allowed;
  box-shadow: none;
}

.btn-show-more {
  background: #3a506b;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin: -30px 10px 10px auto; /* Center the button horizontally */
  max-width: 200px; /* Limit the button width */
  text-align: center;
}


.customer-support-section {
  margin-top: 50px;
  background: #faf9f6;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.customer-support-section h2 {
  font-size: 2rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center;
}

.support-requests-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.support-request-card {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 280px;
  max-width: 350px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.support-request-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.support-request-header {
  font-size: 1.4rem;
  color: #3a506b;
  padding: 8px 16px;
  border-radius: 12px;
  display: inline-block;
  margin: 0 auto 10px auto;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.support-request-header:hover {
  transform: scale(1.04);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.request-label {
  font-weight: 600;
  color: #b89c2a; /* Subtle gold tone */
  margin-right: 6px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}


.support-request-card p {
  font-size: 1rem;
  color: #555555;
  margin-bottom: 8px;
}

.support-message {
  font-style: italic;
  font-size: 1.1rem;
  color: #3a506b;
  background: linear-gradient(135deg, #fefcf5, #faf3e6);
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  max-width: 100%;
  width: fit-content;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.support-message::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 20px;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: #faf3e6 transparent transparent transparent;
}

.support-message:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 14px rgba(0, 0, 0, 0.15);
}


.request-date {
  font-size: 0.95rem;
  color: #3a506b;
  background: linear-gradient(90deg, #f7f7f7, #eaeaea);
  padding: 6px 12px;
  border-radius: 10px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.request-date:hover {
  transform: scale(1.03);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.date-label {
  font-weight: 600;
  color: #b89c2a; /* Subtle gold to align with the theme */
  margin-right: 4px;
  letter-spacing: 0.5px;
}


.btn-respond-support {
  background-color: #3a506b;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin: 10px auto 0;
  max-width: 200px;
  text-align: center;
}

.btn-respond-support:hover {
  background: #2a3b5b;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(42, 59, 91, 0.3);
}

.support-request-card.picked-up {
  border: 2px solid #1aae43;
  background: linear-gradient(135deg, #f7fff7, #e9f5e9);
  transition: background 0.4s ease, border 0.4s ease;
}

.support-request-card.picked-up:hover {
  transform: translateY(-5px);
  background: linear-gradient(135deg, #e9f5e9, #f7fff7);
  box-shadow: 0 12px 24px rgba(26, 174, 67, 0.2);
}

.btn-respond-support.btn-disabled {
  background-color: #b7b7b7;
  cursor: not-allowed;
  box-shadow: none;
}

.support-request-header {
  font-size: 1.4rem;
  color: #3a506b;
  padding: 8px 16px;
  border-radius: 12px;
  display: inline-block;
  margin: 0 auto 10px auto;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.support-request-header:hover {
  transform: scale(1.04);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.support-message {
  font-style: italic;
  font-size: 1.1rem;
  color: #3a506b;
  background: linear-gradient(135deg, #fefcf5, #faf3e6);
  padding: 12px 16px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  max-width: 100%;
  width: fit-content;
  position: relative;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.btn-respond-support {
  background-color: #3a506b;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin: 10px auto 0;
  max-width: 200px;
  text-align: center;
}

.btn-respond-support:hover:not(.btn-disabled) {
  background: #2a3b5b;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(42, 59, 91, 0.3);
}


.btn-drop-support {
  background-color: #dc3545; /* Red color for drop action */
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  display: block;
  margin: 10px auto 0;
  max-width: 200px;
  text-align: center;
}

.btn-drop-support:hover {
  background-color: #c82333;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(220, 53, 69, 0.3);
}

.btn-drop-support:disabled {
  background-color: #b89c2a;
  cursor: not-allowed;
  box-shadow: none;
}




/* Financials Section */
.financials-section {
  margin-top: 50px;
  background: #faf9f6;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.financials-section h2 {
  font-size: 2rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center;
}

.financial-summary {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.income-breakdown h3,
.income-details h4 {
  font-size: 1.5rem;
  color: #3a506b;
  margin-bottom: 10px;
}

.income-breakdown h3 span,
.income-details p span {
  color: #d4af37;
  font-weight: bold;
}

.income-details p {
  font-size: 1.2rem;
  color: #555555;
  margin-bottom: 10px;
}

/* Payment Disbursement Section */
.payment-disbursement {
  margin-top: 30px;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.payment-disbursement h4 {
  font-size: 1.8rem;
  color: #3a506b;
  margin-bottom: 20px;
}

.payment-disbursement-grid {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  text-align: left;
}

.payment-item {
  background: #faf9f6;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.payment-item p {
  font-size: 1rem;
  color: #333;
  margin-bottom: 10px;
}

.payment-item strong {
  color: #3a506b;
}

.payment-item:nth-child(odd) {
  background-color: #fff7e6;
}

/* Chart Section */
.financial-chart-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  margin-top: 30px;
}

.chart-container {
  flex: 2;
  max-width: 600px;
  margin: 30px auto;
}

.income-breakdown-filers {
  flex: 1;
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.income-breakdown-filers h4 {
  font-size: 1.5rem;
  color: #3a506b;
  margin-bottom: 10px;
}

.income-breakdown-filers p {
  font-size: 1.2rem;
  color: #555555;
  margin-bottom: 10px;
}

#claims-chart,
#claims-filers-chart {
  width: 100%;
  height: 400px;
}

/* Notes Modal Styling */
.notes-modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6); /* Darker semi-transparent background */
  backdrop-filter: blur(5px); /* Adds a subtle blur effect to the background */
}

.notes-modal.active {
  display: block; /* Show the modal when active */
}

/* Modal Content Box */
.notes-modal-content {
  background-color: #ffffff;
  margin: 10% auto; /* Centered, with top margin */
  padding: 30px;
  border: 1px solid #d4af37;
  width: 70%; /* Adjust size as needed */
  max-width: 600px; /* Maximum width */
  border-radius: 15px; /* Smooth edges */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); /* Deeper shadow for an elevated effect */
}

/* The Close Button */
.close-notes-modal {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-notes-modal:hover,
.close-notes-modal:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


/* Modal Title */
.modal-title {
  font-size: 2rem;
  color: #3a506b;
  margin-bottom: 20px;
  text-align: center;
}

/* The Close Button */
.close-notes-modal {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-notes-modal:hover,
.close-notes-modal:focus {
  color: #333;
  text-decoration: none;
}

/* Note Input Styling */
.note-input {
  width: 100%;
  height: 120px; /* Slightly taller */
  margin-bottom: 20px;
  padding: 15px;
  border: 2px solid #d4af37; /* Border color matches theme */
  border-radius: 10px; /* Smoother corners */
  resize: none;
  font-family: inherit;
  font-size: 1.1rem; /* Slightly larger font */
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7; /* Light background for input area */
}

/* Save Note Button Styling */
.save-note-btn {
  background-color: #3a506b; /* Matches theme */
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 50px; /* Rounded pill shape */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

.save-note-btn:hover {
  background-color: #2a3b5b;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(42, 59, 91, 0.3);
}

/* Saved Notes Title */
.saved-notes-title {
  font-size: 1.5rem;
  color: #3a506b;
  margin-top: 30px;
  margin-bottom: 10px;
  border-bottom: 2px solid #d4af37;
  padding-bottom: 10px;
}

/* Saved Notes List Styling */
.saved-notes-list {
  list-style: none;
  padding-left: 0;
  margin-top: 20px;
}

.note-item {
  background-color: #faf9f6;
  margin-bottom: 10px;
  padding: 12px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 1rem;
  color: #555555;
  font-style: italic;
}

/* Message Modal Styling */
.message-modal {
  display: block; /* Ensure this is set to block when modal is active */
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}


.message-modal-content {
  background-color: #ffffff;
  margin: 10% auto; /* Centered, with top margin */
  padding: 30px;
  border: 1px solid #d4af37;
  width: 70%; /* Adjust size as needed */
  max-width: 600px; /* Maximum width */
  border-radius: 15px; /* Smooth edges */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); /* Deeper shadow for an elevated effect */
}

.close-message-modal {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-message-modal:hover,
.close-message-modal:focus {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.chat-history {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Custom Scrollbar Styling for Message Modal Chat History */
.chat-history::-webkit-scrollbar {
  width: 12px;
}

.chat-history::-webkit-scrollbar-track {
  background: #faf9f6; /* Match the background of the webpage */
  border-radius: 10px;
}

.chat-history::-webkit-scrollbar-thumb {
  background-color: #d4af37; /* Gold color to match the theme */
  border-radius: 10px;
  border: 3px solid #faf9f6; /* Matches the track background */
}

.chat-history::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342; /* Slightly brighter on hover */
}

.chat-message {
  margin-bottom: 15px;
}

.chat-message .message-sender {
  font-weight: bold;
  color: #3a506b;
  margin-bottom: 5px;
}

.chat-message .message-content {
  padding: 10px;
  background: #e7e7e7;
  border-radius: 10px;
  color: #555;
}

.chat-message.me .message-content {
  background: #d4af37;
  color: #fff;
  text-align: right;
}

.message-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.message-input {
  flex: 1;
  height: 50px;
  padding: 10px;
  border: 2px solid #d4af37;
  border-radius: 10px;
  font-family: inherit;
  font-size: 1rem;
}

.send-message-btn {
  background-color: #3a506b;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 50px; /* Rounded pill shape */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease,
    box-shadow 0.3s ease;
}

.send-message-btn:hover {
  background-color: #2a3b5b;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(42, 59, 91, 0.3);
}

/* Footer Section */
.footer {
  background: #ffffff;
  color: #333333;
  padding: 40px 0;
  text-align: center;
  font-size: 1rem;
  border-top: 2px solid #e5e5e5;
}

.footer p {
  margin-bottom: 10px;
  color: #555555;
}

.footer a {
  color: #d4af37;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer a:hover {
  color: #b89c2a;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -300px;
  width: 300px;
  height: 100%;
  background-color: #f4f4f4;
  box-shadow: -2px 0 12px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
}

.sidebar.active {
  right: 0;
}

.sidebar-header {
  text-align: center;
}

.profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.sidebar-content {
  flex-grow: 1;
  text-align: center;
}

.sidebar-content p {
  font-size: 1.1rem;
  color: #555;
}

.sidebar-footer {
  text-align: center;
}

.btn-logout {
  background-color: #d4af37;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

.btn-logout:hover {
  background-color: #b89c2a;
}

.overlay.active {
  display: block;
}

/* Resolve Modal Styling */
.resolve-modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
  backdrop-filter: blur(5px); /* Subtle background blur */
  justify-content: center;
  align-items: center;
}

.resolve-modal.active {
  display: flex !important; /* Show when active */
}

/* Modal Content */
.resolve-modal-content {
  background-color: #ffffff;
  padding: 30px;
  border: 2px solid #d4af37;
  border-radius: 15px;
  max-width: 600px;
  width: 80%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
}

/* Close Button */
.close-resolve-modal {
  color: #aaa;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  transition: color 0.3s ease;
}

.close-resolve-modal:hover {
  color: #333;
}

/* Modal Title */
.resolve-modal-title {
  font-size: 1.5rem;
  color: #3a506b;
  margin-bottom: 20px;
}

/* Modal Fields */
.modal-field {
  margin-bottom: 15px;
  text-align: left;
}

.modal-field label {
  display: block;
  font-size: 1rem;
  color: #555555;
  margin-bottom: 5px;
}

.modal-field input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease;
}

.modal-field input:focus {
  border-color: #d4af37;
}

/* Resolve Button */
.resolve-btn {
  background-color: #3a506b;
  color: #ffffff;
  padding: 10px 24px;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
}

.resolve-btn:hover {
  background-color: #2a3b5b;
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(42, 59, 91, 0.3);
}

/* Custom Scrollbar Styling for Modal */
.resolve-modal-content::-webkit-scrollbar {
  width: 8px;
}

.resolve-modal-content::-webkit-scrollbar-track {
  background: #faf9f6;
  border-radius: 8px;
}

.resolve-modal-content::-webkit-scrollbar-thumb {
  background-color: #d4af37;
  border-radius: 8px;
  border: 2px solid #faf9f6;
}

.resolve-modal-content::-webkit-scrollbar-thumb:hover {
  background-color: #f9d342;
}



@media (min-width: 768px) {
  .metrics-section {
    flex-direction: row;
    justify-content: space-between;
  }

  .claims-list {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .claim-item {
    flex: 1 1 calc(33.333% - 20px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .claim-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
}
/*
@media (min-width: 1024px) {
  .header-flex {
    justify-content: space-between;
    align-items: center;
  }

  .logo-container {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: -340px;
  }

  .navbar {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: -100px;
  }

  .logo-image {
    width: 120px;
    height: auto;
  }

  .logo-container h1 {
    font-size: 1.7rem;
    color: #d4af37;
  }

  .account-icon {
    width: 110px;
    height: auto;
  }
}
*/